const typography = {
  // fontFamily: ["Montserrat", "Roboto", "Arial", "sans-serif"].join(","),
  h1: {
    fontSize: "3.5rem", // 56
    fontWeight: 700,
    lineHeight: 1.25,
    letterSpacing: "0em",
  },
  h2: {
    fontSize: "2.5rem", // 40
    fontWeight: 700,
    lineHeight: 1.25,
    letterSpacing: "0.015em",
  },
  h3: {
    fontSize: "2rem", // 32
    fontWeight: 600, // semibold
    lineHeight: 1.35,
    letterSpacing: "0em",
  },
  h4: {
    fontSize: "1.5rem", // 24
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: "0.00735em",
  },

  h5: {
    fontSize: "1.25rem", // 20
    fontWeight: 500,
    lineHeight: 1.35,
    letterSpacing: "0.00735em",
  },
  h6: {
    fontSize: "1rem", // 16
    fontWeight: 500,
    lineHeight: 1.35,
    letterSpacing: "0.0075em",
  },
  subtitle1: {
    fontSize: "1.25rem", // 20
    fontWeight: 400,
    lineHeight: 1.6, // 32
    letterSpacing: "0em",
  },
  subtitle2: {
    fontSize: "1.125rem", // 18
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.015em",
  },
  body1: {
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
  },
  body2: {
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.01071em",
  },
  button: {
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1.75,
    letterSpacing: "0.02857em",
    textTransform: "none",
  },
  caption: {
    fontSize: "0.75rem", //12
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.03333em",
  },
  overline: {
    fontSize: "0.75rem", //12
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.08333em",
    textTransform: "uppercase",
  },
}

export default typography
