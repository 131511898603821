import palette from "./palette"
import spacing from "./spacing"
import shape from "./shape"
import typography from "./typography"
import shadows from "./shadows"
import { alpha } from "@mui/material"

const components = {
  // BASELINE
  MuiCssBaseline: {
    styleOverrides: {
      "*": {
        margin: 0,
        padding: 0,
        boxSizing: "border-box",
        overflowWrap: "break-word",
      },
    },
  },
  // TYPOGRAPHY
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        subtitle1: "p",
        subtitle2: "p",
      },
    },
    styleOverrides: {
      gutterBottom: {
        marginBottom: "0.5em",
      },
    },
  },
  // BUTTON
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        fontSize: "0.875rem",
        lineHeight: "1.71429",
      },
      sizeSmall: {
        fontSize: "0.8125rem", // 13
        paddingTop: spacing[1], // 4
        paddingRight: spacing[1] * 1.25, // 5
        paddingBottom: spacing[1], // 4
        paddingLeft: spacing[1] * 1.25, // 5
      },
      sizeMedium: {
        paddingTop: spacing[1] * 2, // 8
        paddingRight: spacing[3], // 16
        paddingBottom: spacing[1] * 2, // 8
        paddingLeft: spacing[3], // 16
      },
      sizeLarge: {
        fontSize: "1rem", // 15
        paddingTop: spacing[1] * 3, // 12
        paddingRight: spacing[4], // 24
        paddingBottom: spacing[1] * 3,
        paddingLeft: spacing[4],
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
    },
  },
  // CARD
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: spacing[4],
        "&:last-child": {
          paddingBottom: spacing[4],
        },
      },
    },
  },

  // INPUT
  MuiInputBase: {
    styleOverrides: {
      root: {
        backgroundColor: palette.common.white,
        position: "relative",
        lineHeight: "1.4375",
      },
      input: {
        // width: "100%",
      },
      inputSizeSmall: {
        padding: `${spacing[2]}px ${spacing[1] * 3.5}px`,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        "&:hover fieldset": {
          borderColor: `${palette.gray[500]}`,
        },
        "& legend": {
          width: 0,
        },
      },
      notchedOutline: {
        border: `1px solid ${palette.gray[300]}`,
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      root: {
        backgroundColor: palette.gray[100],
        borderRadius: shape.borderRadius,
        "&:hover": {
          backgroundColor: palette.gray[100],
        },
      },
      inputSizeSmall: {
        padding: `${spacing[2]}px ${spacing[4]}px`,
      },
    },
  },

  // CHECKBOX

  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },

  MuiCheckbox: {
    styleOverrides: {
      root: {
        padding: 0,
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
    },
  },

  // RADIO

  MuiRadio: {
    styleOverrides: {
      root: {
        padding: 0,
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
    },
  },

  // LIST
  MuiList: {
    styleOverrides: {
      root: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
  // CHIP
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: shape.borderRadius,
        lineHeight: 1,
      },
    },
  },
  // TAB
  MuiTab: {
    styleOverrides: {
      root: {
        "&>*:first-child": {
          marginBottom: 0,
        },
        opacity: 1,
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      indicator: {},
    },
  },
  // ALERT
  MuiAlert: {
    styleOverrides: {
      root: {
        fontSize: "1rem",
        fontWeight: 400,
        alignItems: "center",
      },
      action: {
        paddingTop: 0,
      },
    },
  },
  // TABLE
  MuiTableHead: {
    styleOverrides: {
      root: {
        fontSize: typography.body1,
        "& th": {
          fontSize: typography.body1,
          borderBottom: `1px solid ${palette.divider}`,
        },
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        borderBottom: `1px solid ${palette.divider}`,
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        fontSize: typography.body2,
      },
    },
  },
  // ACCORDION
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        padding: `0 ${spacing[3]}px`,
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: `${spacing[2]}px ${spacing[3]}px ${spacing[2]}px`,
      },
    },
  },

  // MENU
  MuiMenuItem: {
    styleOverrides: {
      root: {
        "&.Mui-selected": {
          backgroundColor: palette.gray[100],
          "&:hover": {
            backgroundColor: alpha(palette.secondary.main, 0.05),
          },
        },
        "&:hover": {
          backgroundColor: alpha(palette.secondary.main, 0.05),
        },
      },
    },
  },

  // AUTOCOMPLETE

  MuiAutocomplete: {
    styleOverrides: {
      listbox: {
        "& .MuiAutocomplete-option[aria-selected='true']": {
          backgroundColor: alpha(palette.secondary.main, 0.1),
        },
      },
      option: {
        "&[aria-selected='true'].Mui-focused": {
          backgroundColor: alpha(palette.secondary.main, 0.1),
        },
      },
    },
  },

  // POPOVER
  MuiPopover: {
    styleOverrides: {
      paper: {
        borderRadius: "0px",
        boxShadow: shadows[2],
      },
    },
  },
}

export default components
