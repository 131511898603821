const palette = {
  mode: "light",
  common: {
    white: "#fff",
    black: "#000",
    pink: "#efb9b9",
    fuchsia: "#f206aa",
    orchid: "#de6be7",
    purpleMountain: "#9669a1",
    purpleHeart: "#6a4199",
    blue: "#1573fc",
    azure: "#3579bc",
    sapphire: "#1e5787",
    skobeloff: "#00687e",
    bubbles: "#e5f8fb",
    cyan: "#00bbdc",
    tiffany: "#01b1c2",
    persianGreen: "#00a5a1",
    electricBlue: "#56f7f0",
    turquoise: "#63e7ce",
    caribbeanGreen: "#00d286",
    seaGreen: "#23a248",
    deepGreen: "#05740b",
    bitterLemon: "#c4d600",
    bananaYellow: "#ffde29",
    goldenPoppy: "#fdc600",
    royalOrange: "#f69a40",
    heatWave: "#ff7b00",
    fireOpal: "#ea5f3c",
  },
  primary: {
    light: "#ff7474",
    main: "#ed1c1c",
    dark: "#be0909",
  },
  secondary: {
    light: "#9265c2",
    main: "#5f259f",
    dark: "#481283",
  },
  error: {
    light: "#f94a4a",
    main: "#ed1c1c",
    dark: "#be0909",
  },
  warning: {
    light: "#feefe0",
    main: "#f69a40",
    dark: "#ab5808",
  },
  info: {
    light: "#03d7ff",
    main: "#00bbdc",
    dark: "#008199",
  },
  success: {
    light: "#dcf0e2",
    main: "#23a248",
    dark: "#1a7a36",
  },
  gray: {
    50: "#fafafa", // docs bgr
    100: "#eaeaea", // divider
    200: "#eaeaea", // ??
    300: "#e0e0e0",
    400: "#bcbcbc", // chip outlined ?? bdbdbd
    500: "#999999",
    600: "#757575",
    700: "#666666",
    800: "#424242",
    900: "#27282c", // footer
    A100: "#f4f4f4",
    A200: "#efefef",
    A400: "#bcbcbc",
    A700: "#666666",
  },
  blueGray: {
    50: "#faf8fc",
    100: "#F1F7FD",
    500: "#d2dded",
  },
  text: {
    primary: "#000000",
    secondary: "#707070",
    disabled: "rgba(0, 0, 0, 0.26)",
  },
  divider: "#eaeaea", // gray[100]
  background: {
    paper: "#ffffff",
    default: "#ffffff",
  },
  action: {
    active: "rgba(0, 0, 0, 0.54)",
    hover: "rgba(0, 0, 0, 0.04)",
    hoverOpacity: 0.04,
    selected: "rgba(0, 0, 0, 0.08)",
    selectedOpacity: 0.08,
    disabled: "rgba(0, 0, 0, 0.1)",
    disabledBackground: "rgba(0, 0, 0, 0.12)",
    disabledOpacity: 0.38,
    focus: "rgba(0, 0, 0, 0.12)",
    focusOpacity: 0.12,
    activatedOpacity: 0.12,
  },
  finder: {
    primary: {
      main: "#019ee1",
      dark: "",
    },
    secondary: {
      main: "#283756",
      light: "#324365",
    },
  },
  rpi: {
    black: "#24292f",
    primary: {
      light: "#df4372",
      main: "#cd2355",
      dark: "",
    },
    secondary: {
      main: "#53ce63",
      dark: "",
    },

    gradient: {
      main: "linear-gradient(180deg,#ececec,#f5f5f5 0%,#cc2455 0,#d75965)",
    },
  },
  omron: {
    black: "#14181c",
    primary: {
      dark: "#004f99",
      main: "#005fb9",
      light: "#306ccb",
    },
  },
  syntax: {
    background: "#f7f3f7",
    secondary: {
      dark: "#332c51",
    },
  },
}

export default palette
