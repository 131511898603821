const shadows = [
  "none",
  "0px 2px 4px rgba(0,0,0,.1)",
  "0px 4px 8px rgba(0,0,0,.12)",
  "0 5px 10px rgba(0,0,0,.12)",
  "0 8px 30px rgba(0,0,0,.12)",
  "0 30px 60px rgba(0,0,0,.12)",
]

export default shadows
