/* eslint-disable import/prefer-default-export */
import * as React from "react"
import TopLayout from "./TopLayout"

import "bootstrap/dist/js/bootstrap.bundle.min.js"
import "../../src/styles/global.css"

export const wrapRootElement = ({ element }) => {
  return <TopLayout>{element}</TopLayout>
}
